import React, { useEffect } from 'react';
import { NavLink } from "@/components/ui";
import { IPageMenu } from "@/interfaces/IPages";
import styles from './Header.module.css';

type Props = {
    HeaderData: IPageMenu[]
}

export function HeaderChilds({ HeaderData }: Props) {
    useEffect(() => {
        HeaderData && HeaderData.length > 0 && HeaderData.forEach((menu) => {
            if (menu.child && menu.child.length > 0) {
                const targetDiv = document.getElementById(menu.pageName.textToSlug());
                const content = document.getElementById(`submenu-${menu.pageName.textToSlug()}`);
                if (targetDiv && content) {
                    targetDiv.appendChild(content);
                }
            }
        });
    }, [HeaderData]);
    return (
        <>
            {HeaderData.map((menu, menuIdx) => (
                menu.child && menu.child.length > 0 && (
                    <div
                        key={menuIdx}
                        id={`submenu-${menu.pageName.textToSlug()}`}
                        className={menu.child.length > 13 ? `${styles.subDropdown} ${styles.asideSubDropdwn}` : styles.subDropdown}
                    >
                        <ul>
                            {menu.child.map((subMenu, subMenuIdx) => (
                                <li key={subMenuIdx}>
                                    <NavLink href={subMenu.pageUrl}>
                                        {subMenu.pageName === "Gurgaon" ? "Gurugram" : subMenu.pageName}
                                    </NavLink>
                                    {subMenu.child && subMenu.child.length > 0 && (
                                        <div className={styles.menuTabInner}>
                                            <div className={styles.menuGridBox}>
                                                {subMenu.child.map((subSubMenu, subSubMenuIdx) => (
                                                    <div className={styles.menuTypeMain} key={subSubMenuIdx}>
                                                        <span className={styles.menuTypeHead}>
                                                            {`By ${subSubMenu.pageName}`}
                                                        </span>
                                                        <ul>
                                                            {subSubMenu.child && subSubMenu.child.length > 0  && subSubMenu.child.map((f, fIdx) => (
                                                                <li key={fIdx}>
                                                                    <NavLink href={f.pageUrl}>{f.pageName}</NavLink>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            ))}
        </>
    );
}